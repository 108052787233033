import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationAdminGuard, AuthenticationGuard } from './@core';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'cda-admin',
      canActivate: [AuthenticationGuard, AuthenticationAdminGuard],
      loadChildren: () => import('./content/cda-admin/cda-admin.module').then((m) => m.CdaAdminModule),
    },
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
