import { NgOptionHighlightDirective } from './pipes/ng-option-highlight.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BootstrapModule } from '@app/bootstrap/bootstrap.module';
import { CardsModule } from '@app/blocks/cards/cards.module';
import { AlertsModule } from '@app/blocks/alerts/alerts.module';
import { FormControlsModule } from '@app/blocks/form-controls/form-controls.module';
import { IconsModule } from '@app/blocks/icons/icons.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { UnsanitizePipe } from './pipes/unsanitize.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CustomNumberPipe } from './pipes/custom-number.pipe';
import { MonthPipe } from './pipes/month.pipe';
import { ProductunitPipe } from './pipes/productunit.pipe';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';

const exportModules = [
  // external modules
  CommonModule,
  HttpClientModule,
  RouterModule,
  TranslateModule,
  PerfectScrollbarModule,

  // custom modules
  BootstrapModule,
  CardsModule,
  AlertsModule,
  IconsModule,
  FormControlsModule,

  //PrimeNg modules
  TableModule,
  ButtonModule,
];

@NgModule({
  declarations: [
    UnsanitizePipe,
    CustomDatePipe,
    CustomNumberPipe,
    MonthPipe,
    ProductunitPipe,
    NgOptionHighlightDirective,
  ],
  imports: [...exportModules],
  exports: [UnsanitizePipe, CustomDatePipe, CustomNumberPipe, NgOptionHighlightDirective, ...exportModules],
})
export class SharedModule {}
