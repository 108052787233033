import { Injectable } from '@angular/core';
import { Enterprise, User, UserConverter } from '@app/content/models';
import { Subject } from 'rxjs/internal/Subject';

import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private db = firebase.default.firestore();
  private authFirebase = firebase.default.auth();

  constructor() {}

  public async createEmploye(enterprise: Enterprise, employee: User): Promise<User> {
    let enterpriseRef = this.db.collection('customers');
    let employeeRef = enterpriseRef.doc(enterprise.EnterpriseCode).collection('employees');

    let employeesCount = 0;

    const userAuth = await this.authFirebase.createUserWithEmailAndPassword(employee.Email, '123456');

    if (!userAuth) {
      throw new Error('Email is already used');
    }

    this.authFirebase
      .sendPasswordResetEmail(employee.Email, { url: 'http://localhost:4200/login', handleCodeInApp: true })
      .then((result) => {
        console.log(' EMAIL SEND TO ' + employee.Email, result);
      })
      .catch((err) => {
        console.log('ERROR TO SEND EMAIL TO ' + employee.Email, err);
      });

    //Increment user's orders counter. don't use natural increment, use fierebase.fieldValue.increment to keep data synced throught all users
    await enterpriseRef
      .doc(enterprise.EnterpriseCode)
      .update({ EmployeeCount: firebase.default.firestore.FieldValue.increment(1) });

    //get current count ref from db with fresh data
    let dbEmployeesCounter = await enterpriseRef.doc(enterprise.EnterpriseCode).get();

    employeesCount = Math.floor(dbEmployeesCounter.data().EmployeeCount);

    //get 6 digit from orderscount number
    const last3digits = employeesCount.toLocaleString('en-US', { minimumIntegerDigits: 3, useGrouping: false });
    //create order code with template BENSxxxxExxx
    let employeeCode = enterprise.EnterpriseCode + last3digits;

    employee.UserCode = employeeCode;
    employee.EnterpriseCode = enterprise.EnterpriseCode;
    employee.Id = employeesCount;

    console.log('saving employee:', Object.assign({}, employee));

    const getEmployee = await (await employeeRef.doc(employee.UserCode).withConverter(UserConverter).get()).data();

    if (getEmployee && getEmployee.Email.toLocaleLowerCase() === employee.Email.toLocaleLowerCase()) {
      throw new Error('Email already exit');
    }

    await employeeRef.doc(employee.UserCode).set(JSON.parse(JSON.stringify(employee)));

    return employee;
  }

  public async getEmployeeBelongsToEnterprises(enterpriseCode: string): Promise<User[]> {
    const employeesList: User[] = [];

    let employeesRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('employees')
      .withConverter(UserConverter);

    await employeesRef.get().then((snap) => {
      snap.forEach((doc) => {
        employeesList.push(doc.data());
      });
    });

    console.log('users enterprise: ', employeesList);

    return employeesList;
  }

  public async getEmployeByCode(enterpriseCode: string, employeeCode: string): Promise<User> {
    let employeeRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('employees')
      .doc(employeeCode)
      .withConverter(UserConverter);

    const employee = (await employeeRef.get()).data();

    return employee;
  }

  public async updateEmployee(enterpriseCode: string, employee: User): Promise<User> {
    let enterpriseRef = this.db.collection('customers');
    let employeeRef = enterpriseRef.doc(enterpriseCode).collection('employees');

    await employeeRef.doc(employee.UserCode).update(JSON.parse(JSON.stringify(employee)));

    return employee;
  }

  public async deleteEmploye(enterpriseCode: string, employee: User): Promise<any> {
    let enterpriseRef = this.db.collection('customers');
    let employeeRef = enterpriseRef.doc(enterpriseCode).collection('employees');

    return await employeeRef.doc(employee.UserCode).delete();
  }
}
