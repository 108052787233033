<h2 class="text-dark bold" translate>Register</h2>

<p class="mb-5">
  <span translate>Already have an account?</span>
  <a routerLink="/login" class="text-primary bold" translate>Login here</a>
</p>

<form (ngSubmit)="register()" [formGroup]="form" class="cozy" novalidate>
  <div class="alert alert-danger alert-icon" [hidden]="!error || isLoading" translate>
    An error has occured, please check your information
  </div>

  <div class="form-group">
    <label class="control-label bold small text-uppercase" translate>Full Name</label>
    <input
      type="text"
      formControlName="fullName"
      class="form-control bg-light form-control-rounded"
      [placeholder]="'Full Name' | translate"
    />
    <small [hidden]="form.controls.fullName.valid || form.controls.fullName.untouched" class="text-danger" translate>
      Full name is required
    </small>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <label class="control-label bold small text-uppercase" translate>Username</label>
      <input
        type="text"
        formControlName="username"
        class="form-control bg-light form-control-rounded"
        [placeholder]="'Username' | translate"
      />
      <small [hidden]="form.controls.username.valid || form.controls.username.untouched" class="text-danger" translate>
        Username is required
      </small>
    </div>

    <div class="col-md-6">
      <label class="control-label bold small text-uppercase" translate>Email</label>
      <input
        type="text"
        formControlName="email"
        class="form-control bg-light form-control-rounded"
        [placeholder]="'Email' | translate"
      />
      <small [hidden]="form.controls.email.valid || form.controls.email.untouched" class="text-danger" translate>
        Email is required
      </small>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <label class="control-label bold small text-uppercase" translate>Password</label>
      <input
        type="password"
        formControlName="password"
        class="form-control bg-light form-control-rounded"
        [placeholder]="'Password' | translate"
      />
      <small [hidden]="form.controls.password.valid || form.controls.password.untouched" class="text-danger" translate>
        Password is required
      </small>
    </div>

    <div class="col-md-6">
      <label class="control-label bold small text-uppercase">Confirm Password</label>
      <input
        type="password"
        formControlName="confirmPassword"
        class="form-control bg-light form-control-rounded"
        [placeholder]="'Confirm Password' | translate"
      />
      <small
        [hidden]="form.controls.confirmPassword.valid || form.controls.confirmPassword.untouched"
        class="text-danger"
        translate
      >
        Password confirmation is required
      </small>
    </div>
  </div>

  <div class="form-group d-flex align-items-center justify-content-end">
    <button type="submit" class="btn btn-primary btn-rounded" [disabled]="form.invalid || isLoading">
      <span translate>Register</span>
      <fa-icon [icon]="longArrowAltRight" class="ml-2"></fa-icon>
    </button>
  </div>
</form>
