import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe extends DatePipe {
  public transform(value: any, ...args: any[]): any {
    try {
      //console.log("Date conversion in::",date);
      let dt = super.transform(value, 'dd/MM/yyyy');
      //console.log("Date conversion out::",dt);
      return dt;
    } catch (error) {
      return 'Invalid Date';
    }
  }
}
