import { TfCommand } from './tf-command';

export class Enterprise {
  Email: string;
  EmployeeCount: number;
  EnterpriseCode: string;
  EnterpriseName: string;
  OrdersCount: number;
  Discount: number;

  Orders?: TfCommand[];
}

export const EnterpriseConverter = {
  toFirestore(c: Enterprise): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): Enterprise {
    const data = snapshot.data(options);

    let c = new Enterprise();
    c.Email = data.Email;
    c.EnterpriseName = data.EnterpriseName;
    c.EnterpriseCode = data.EnterpriseCode;
    c.EmployeeCount = data.EmployeeCount;
    c.OrdersCount = data.OrdersCount;
    c.Orders = data.orders;

    return c;
  },
};
