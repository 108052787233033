import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/@core';
import * as firebase from 'firebase';
import { Observable, of, Subject } from 'rxjs';
import { Product, ProductConverter } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private db = firebase.default.firestore();

  constructor(private _credentialsService: CredentialsService) {
    //this.init();
  }

  private _products: Product[] = [];

  public productsSubject = new Subject<Product[]>();

  public emitProductsSubject() {
    this.productsSubject.next(this._products.slice());
  }

  public async getProducts() {
    this._products = [];

    //let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let productsRef = this.db.collection('products').withConverter(ProductConverter);

    await productsRef.get().then((snap) => {
      snap.forEach((doc) => {
        if (doc.data().ProductCode) this._products.push(doc.data());

        console.log('data got :', doc.data());
      });
    });

    //console.log('got from firesotre :', this._products);

    this.emitProductsSubject();

    return this._products;
  }

  public async getProductByCode(productCode: string): Promise<Product> {
    //let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let productsRef = this.db.collection('products').doc(productCode).withConverter(ProductConverter);

    const p = await productsRef.get();

    if (p.exists) {
      return p.data();
    }
  }

  public async getProductByRefCode(productRefCode: string): Promise<Product> {
    //let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let productsRef = this.db
      .collection('products')
      .where('ProductRefCode', '==', productRefCode)
      .withConverter(ProductConverter);

    const p = await productsRef.get();

    if (p.docs[0]) {
      return p.docs[0].data();
    }
  }

  public async getProductById(id: number) {
    console.log('in product list :', this._products);

    if (this._products.length <= 0) {
      await this.getProducts();

      console.log('loaded commands :', this._products);
    }

    let p = this._products.filter((x) => x.ProductId == id)[0];
    console.log(id, p);

    return new Promise((resolve, reject) => {
      resolve(p);
    });
  }

  public async createProduct(product: Product) {
    let productsRef = this.db.collection('products');
    let counterRef = this.db.collection('counterRefs');

    let productsCount = 0;

    //Increment user's orders counter. don't use natural increment, use fierebase.fieldValue.increment to keep data synced throught all users
    await counterRef.doc('products').update({ ProductsCount: firebase.default.firestore.FieldValue.increment(1) });

    //get current user from db with fresh data
    let dbProductCounter = await counterRef.doc('products').get();

    productsCount = Math.floor(dbProductCounter.data().ProductsCount);

    //get 6 digit from orderscount number
    const last4digits = productsCount.toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false });
    const userId4Digit = this._credentialsService.credentials.Id.toLocaleString('en-US', {
      minimumIntegerDigits: 4,
      useGrouping: false,
    });
    //create order code with template BExx...xODRxxxxxx
    let productCode = 'BE' + userId4Digit + (Math.floor(Math.random() * 10) + 1) + 'PROD' + last4digits;

    product.ProductCode = productCode;
    product.ProductId = productsCount;

    console.log('saving product:', Object.assign({}, product));

    await productsRef.doc(product.ProductCode).set(JSON.parse(JSON.stringify(product)));

    this._products.push(product);

    this.emitProductsSubject();

    return new Promise<Product>((rs, rj) => rs(product));
  }

  public async updateProduct(product: Product) {
    //let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let productsRef = this.db.collection('products');

    console.log('saving product:', Object.assign({}, product), JSON.parse(JSON.stringify(product)));

    await productsRef.doc(product.ProductCode).update(JSON.parse(JSON.stringify(product)));

    let any = this._products.filter((x) => x.ProductCode === product.ProductCode);
    let c: Product;
    if (any) {
      c = any[0];

      Object.assign(c, product);
    } else {
      c = product;
    }

    this.emitProductsSubject();
  }

  public async deleteProduct(product: Product) {
    //let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let productsRef = this.db.collection('products');

    await productsRef.doc(product.ProductCode).delete();

    this._products = this._products.filter((x) => x.ProductCode !== product.ProductCode);

    this.emitProductsSubject();
  }

  /* public async addOrderedProducts(cartProducts: CartProduct[], orderCode: string) {
    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let orderedProductRef = currUserRef.collection('orders').doc(orderCode).collection('OrderedProducts');
    cartProducts.forEach(async (p) => {
      console.log('adding product :', JSON.stringify(p));

      await orderedProductRef.doc(p.ProductCode).set(JSON.parse(JSON.stringify(p)));

      this._orderedProducts.push(p);
    });

    this.emitOrderedProductsSubject();
  }

  public async getOrderedProductsByOrderCode(orderCode: string) {
    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let orderedProductRef = currUserRef
      .collection('orders')
      .doc(orderCode)
      .collection('OrderedProducts')
      .withConverter(CartProductConverter);

    this._orderedProducts = [];

    await orderedProductRef.get().then((snap) => {
      snap.forEach((doc) => {
        this._orderedProducts.push(doc.data());
      });
    });

    this.emitOrderedProductsSubject();
  } */

  init() {
    this.getProducts().then();
  }
}
