<div class="media align-items-center justify-content-center">
  <!-- <prx-avatar> should be placed inside content -->
  <ng-content></ng-content>

  <div class="media-body text-truncate title">
    <p class="media-heading bold text-muted user-name text-capitalize mb-1 box" *ngIf="name">{{ name }}</p>
    <div class="small user-info text-muted text-truncate box" *ngIf="info; else infoContent">{{ info }}</div>
    <ng-template #infoContent>
      <ng-content select="[avatar-info]"></ng-content>
    </ng-template>
  </div>
</div>
