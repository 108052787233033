import { EnterpriseMgtService } from './enterprise-mgt.service';
import { ProductsStockService } from '@app/content/cda-common/services';
import { ProductBudget, ProductBudgetConverter } from './../../models/product-budget';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { ProductsService } from './products.service';

import * as _ from 'lodash';

import { CartProduct, Product, TfCommand } from '@app/content/models';
import { MonthPipe } from '@app/@shared/pipes/month.pipe';
import { Logger } from '@app/@core';

const logger = new Logger('BudgetService');

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  private db = firebase.default.firestore();

  public budgetPeyYearPerProductForEnterpriseSubject = new Subject<any[]>();
  totalBudgetOfEnterprise: ProductBudget[] = [];

  public productTypeFromBudgetSubject = new Subject<any[]>();
  productTypeFromBudgetList: string[] = [];

  private allProducts: Product[] = [];

  constructor(
    private productService: ProductsService,
    private _productsStockService: ProductsStockService,
    private _productsService: ProductsService,
    private _enterpriseMgtService: EnterpriseMgtService
  ) {}

  public async resumeBudgetPerYearForProjectOfEnterprise(enterpriseCode: string): Promise<any[]> {
    let budgetGrouped = await this.getEnterpriseBudgetGroupedByProjectAndYear(enterpriseCode);

    logger.info('grouped items by 1 ==>:', budgetGrouped);

    return budgetGrouped;
  }

  public async getBudgetPeyYearPerProductForEnterprise(enterpriseCode: string) {
    let budgetGrouped = await this.getEnterpriseBudgetGroupedByProjectAndYear(enterpriseCode);

    logger.info('grouped items by 2 ==>:', budgetGrouped);

    return budgetGrouped;
  }

  public async getProductBudgetByCodeForEnterprise(
    enterpriseCode: string,
    productBudgetCode: string
  ): Promise<ProductBudget> {
    let productBudgetRef = this.db
      .collection('budgets')
      .doc(enterpriseCode)
      .collection('monthlyBudget')
      .doc(productBudgetCode)
      .withConverter(ProductBudgetConverter);

    const pb = await productBudgetRef.get();

    if (pb.exists) {
      return pb.data();
    }
  }

  public async getEnterpriseBudgetGroupedByProjectAndYear(enterpriseCode: string) {
    let budgets = await this.getAllBugetsByEnterprise(enterpriseCode);

    let helper = {};
    let grouped = budgets.reduce((memo: ProductBudget[], item) => {
      let key = item.ProjectCode + '-' + item.YearCode;

      if (!helper[key]) {
        helper[key] = Object.assign({}, item); // create a copy of o
        memo.push(helper[key]);
      } else {
        helper[key].BudgetAmount += item.BudgetAmount;
        helper[key].BudgetUsed += item.BudgetUsed;
        helper[key].Quantity += item.Quantity;
        helper[key].QuantityUsed += item.QuantityUsed;
      }

      return memo;
    }, []);

    const sortedGrouped = _.orderBy(grouped, ['ProjectCode', 'YearCode'], ['asc', 'desc']);
    return sortedGrouped;
  }

  public async getEnterpriseBudgetGroupedByProjectByProductByYear(enterpriseCode: string) {
    let budgets = await this.getAllBugetsByEnterprise(enterpriseCode);

    //let allproducts=await this._productsService.getProducts();

    let helper = {};
    let grouped = budgets.reduce((memo: ProductBudget[], item) => {
      let key = item.ProjectCode + '-' + item.Product.ProductType + '-' + item.YearCode;

      if (!helper[key]) {
        helper[key] = Object.assign({}, item); // create a copy of o
        memo.push(helper[key]);
      } else {
        helper[key].BudgetAmount += item.BudgetAmount;
        helper[key].BudgetUsed += item.BudgetUsed;
        helper[key].Quantity += item.Quantity;
        helper[key].QuantityUsed += item.QuantityUsed;
      }

      return memo;
    }, []);

    const sortedGrouped = _.orderBy(grouped, ['ProjectCode', 'YearCode'], ['asc', 'desc']);

    return sortedGrouped;
  }

  public async getAllBugetsByEnterpriseOrdered(enterpriseCode: string) {
    const budgets = await this.getAllBugetsByEnterprise(enterpriseCode);

    const sortedGrouped = _.orderBy(budgets, ['ProjectCode', 'YearCode', 'MonthCode'], ['asc', 'desc', 'desc']);

    return sortedGrouped;
  }

  private async getAllBugetsByEnterprise(enterpriseCode: string) {
    let enterpriseBudgetRef = this.db
      .collection('budgets')
      .doc(enterpriseCode)
      .collection('monthlyBudget')
      .withConverter(ProductBudgetConverter);

    let snapData = await enterpriseBudgetRef.get();

    this.allProducts = await this._productsService.getProducts();

    let budgets: ProductBudget[] = [];

    snapData.forEach((d) => {
      const data = d.data();
      const p = this.allProducts.filter((x) => x.ProductRefCode === data.RefProduct)[0];
      data.Product = p;
      budgets.push(data);
    });

    return budgets;
  }

  public async UpdateBudgetProduct(enterpriseCode: string, productBudget: ProductBudget) {
    let budgetCode = this.getBudgetCode(
      productBudget.YearCode,
      productBudget.MonthCode,
      productBudget.Product.ProductCode,
      productBudget.ProjectCode
    );

    const productBudgetToSave: ProductBudget = JSON.parse(JSON.stringify(productBudget));
    delete productBudgetToSave.Product;

    let productBudgetRef = this.db
      .collection('budgets')
      .doc(enterpriseCode)
      .collection('monthlyBudget')
      .doc(budgetCode);

    await productBudgetRef.update(JSON.parse(JSON.stringify(productBudgetToSave)));

    return productBudget;
  }

  // We perform this method by running transaction
  public async createBudgetProductsFromImportedData(data: ProductBudget[], createOne = false): Promise<any> {
    return await this.db.runTransaction(async (transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      for (const elt of data) {
        const client = await this._enterpriseMgtService.getEnterpriseByCodeFromFireBase(elt.EnterpriseCode);
        if (!client) {
          throw new Error(`Le client avec pour code ${elt.EnterpriseCode} n'existe pas`);
        }

        //check if the project exist, if not reject all the operations
        const proj = await this._productsStockService.getProjectOfEnterpriseByCode(elt.EnterpriseCode, elt.ProjectCode);
        if (!proj) {
          throw new Error(`Le projet(entrepot) avec pour code ${elt.ProjectCode} n'existe pas`);
        }

        //check if the product exist, if not reject all the operations
        const getProduct = await this._productsService.getProductByRefCode(elt.RefProduct);
        if (!getProduct) {
          throw new Error(`Le produit avec pour Reference ${elt.RefProduct} n'existe pas`);
        }

        let budgetCode = this.getBudgetCode(elt.YearCode, elt.MonthCode, getProduct.ProductCode, elt.ProjectCode);

        let budget = await this.getProductBudgetByCodeForEnterprise(elt.EnterpriseCode, budgetCode);

        let destinationBudgetRef = this.db
          .collection('budgets')
          .doc(elt.EnterpriseCode)
          .collection('monthlyBudget')
          .doc(budgetCode);

        const productBudgetToSave: ProductBudget = JSON.parse(JSON.stringify(elt));
        delete productBudgetToSave.Product;

        if (
          budget &&
          (budget.BudgetUsed > 0 || budget.BudgetAmount > 0 || budget.Quantity > 0 || budget.QuantityUsed > 0)
        ) {
          delete productBudgetToSave.BudgetUsed;
          delete productBudgetToSave.QuantityUsed;
          console.log('log delete space ::::', productBudgetToSave);
          await transaction.update(destinationBudgetRef, productBudgetToSave);
        } else {
          await transaction.set(destinationBudgetRef, { ...productBudgetToSave });
        }
        console.log('log save space ::::', productBudgetToSave);
        return productBudgetToSave;
      }
    });
  }

  getBudgetCode(yearCode: number, monthCode: number, productCode: string, projectCode: string) {
    return (
      projectCode +
      '-' +
      productCode +
      '-' +
      yearCode +
      '' +
      monthCode.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    );
  }
  /* async updateProductBugdet(productBudget: ProductBudget) {
    console.log('UPDATE productBudget ====> ', productBudget);
  } */

  // We perform this method by running transaction
  public async updateBudgetProductFromOrder(
    enterpriseCode: string,
    order: TfCommand,
    cartProducts: CartProduct[],
    year: number,
    month: number
  ): Promise<any> {
    return this.db.runTransaction(async (transaction) => {
      // This code may get re-run multiple times if there are conflicts.

      let discountRate = 1;
      if (order.Discount) discountRate = (100 - order.Discount) / 100;

      for (const cartProduct of cartProducts) {
        let budgetCode = this.getBudgetCode(year, month, cartProduct.ProductCode, order.Project.Code);

        let destinationBudgetRef = this.db
          .collection('budgets')
          .doc(enterpriseCode)
          .collection('monthlyBudget')
          .doc(budgetCode)
          .withConverter(ProductBudgetConverter);

        const getProductBuget = await destinationBudgetRef.get();

        let getProductBugetData: ProductBudget = new ProductBudget();
        if (getProductBuget.exists) {
          getProductBugetData = getProductBuget.data();
        } else {
          getProductBugetData.MonthCode = month;
          getProductBugetData.YearCode = year;
          getProductBugetData.RefProduct = cartProduct.ProductRefCode;
          getProductBugetData.EnterpriseCode = enterpriseCode;
          getProductBugetData.ProjectCode = order.Project.Code;
          getProductBugetData.Quantity = 0;
          getProductBugetData.BudgetAmount = 0;
          //getProductBugetData.LastRevision=
        }

        getProductBugetData.QuantityUsed = isNaN(getProductBugetData.QuantityUsed)
          ? cartProduct.Quantity
          : getProductBugetData.QuantityUsed + cartProduct.Quantity;
        getProductBugetData.BudgetUsed = isNaN(getProductBugetData.BudgetUsed)
          ? cartProduct.Quantity * cartProduct.BasePrice * discountRate
          : getProductBugetData.BudgetUsed + cartProduct.Quantity * cartProduct.BasePrice * discountRate;

        logger.info('updatting budget from command ::::', getProductBugetData, discountRate);

        await transaction.set(destinationBudgetRef, { ...getProductBugetData });
      }
    });
  }

  emitTotalBudgetForEnterprise() {
    this.budgetPeyYearPerProductForEnterpriseSubject.next(this.totalBudgetOfEnterprise.slice());
  }

  emitProductTypeFromBudget() {
    this.productTypeFromBudgetList.unshift('ALL');
    this.productTypeFromBudgetList = [...new Set(this.productTypeFromBudgetList)];
    this.productTypeFromBudgetSubject.next(this.productTypeFromBudgetList.slice());
  }

  emitClearTotalBudgetForEnterprise() {
    this.totalBudgetOfEnterprise = [];
    this.budgetPeyYearPerProductForEnterpriseSubject.next([]);

    this.productTypeFromBudgetList = [];
    this.productTypeFromBudgetSubject.next([]);
  }
}
