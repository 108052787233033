import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/@core';
import * as firebase from 'firebase';
import { Observable, of, Subject } from 'rxjs';
import { CartProduct, CartProductConverter, EnterpriseConverter, TfCommand, TfCommandConverter } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CommandsService {
  private db = firebase.default.firestore();

  constructor(private _credentialsService: CredentialsService) {
    //this.init();
  }

  private _tfCommands: TfCommand[] = [];

  private _orderedProducts: CartProduct[] = [];

  public tfCommandsSubject = new Subject<TfCommand[]>();
  public orderedProductsSubject = new Subject<CartProduct[]>();

  public emitTfCommandsSubject() {
    this.tfCommandsSubject.next(
      this._tfCommands.slice().sort((a, b) => new Date(b.CommandDate).getTime() - new Date(a.CommandDate).getTime())
    );
  }

  public emitOrderedProductsSubject() {
    this.orderedProductsSubject.next(this._orderedProducts.slice());
  }

  public async getTfCommands() {
    this._tfCommands = [];

    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let ordersRef = currUserRef.collection('orders').withConverter(TfCommandConverter);

    await ordersRef.get().then((snap) => {
      snap.forEach((doc) => {
        this._tfCommands.push(doc.data());
        console.log('data got :', doc.data());
      });
    });

    console.log('got from firesotre :', this._tfCommands);

    this.emitTfCommandsSubject();
  }

  public async getTfCommandById(id: number) {
    console.log('in commandlist :', this._tfCommands);

    if (this._tfCommands.length <= 0) {
      await this.getTfCommands();

      console.log('loaded commands :', this._tfCommands);
    }

    let p = this._tfCommands.filter((x) => x.CommandId == id)[0];
    console.log(id, p);

    return new Promise((resolve, reject) => {
      resolve(p);
    });
  }

  public async createTfCommand(tfCommand: TfCommand) {
    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);

    let ordersCount = 0;

    //Increment user's orders counter. don't use natural increment, use fierebase.fieldValue.increment to keep data synced throught all users
    await currUserRef.update({ OrdersCount: firebase.default.firestore.FieldValue.increment(1) });

    //get current user from db with fresh data
    let dbUser = await currUserRef.get();

    ordersCount = Math.floor(dbUser.data().OrdersCount);

    //get 6 digit from orderscount number
    const last4digits = ordersCount.toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false });
    const userId4Digit = this._credentialsService.credentials.Id.toLocaleString('en-US', {
      minimumIntegerDigits: 4,
      useGrouping: false,
    });
    //create order code with template BExx...xODRxxxxxx
    let orderCode = 'BE' + userId4Digit + (Math.floor(Math.random() * 10) + 1) + 'ODR' + last4digits;

    tfCommand.OrderCode = orderCode;
    tfCommand.CommandId = ordersCount;
    tfCommand.CommandDate = new Date();
    tfCommand.OrderStatus = 2;

    let ordersRef = currUserRef.collection('orders');

    console.log('saving command:', Object.assign({}, tfCommand));

    await ordersRef.doc(tfCommand.OrderCode).set(JSON.parse(JSON.stringify(tfCommand)));

    this._tfCommands.push(tfCommand);

    this.emitTfCommandsSubject();

    return new Promise<TfCommand>((rs, rj) => rs(tfCommand));
  }

  public async updateTfCommand(tfCommand: TfCommand) {
    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let ordersRef = currUserRef.collection('orders');

    console.log('saving command:', Object.assign({}, tfCommand), JSON.parse(JSON.stringify(tfCommand)));

    await ordersRef.doc(tfCommand.OrderCode).update(JSON.parse(JSON.stringify(tfCommand)));

    let any = this._tfCommands.filter((x) => x.OrderCode === tfCommand.OrderCode);
    let c: TfCommand;
    if (any) {
      c = any[0];

      Object.assign(c, tfCommand);
    } else {
      c = tfCommand;
    }

    this.emitTfCommandsSubject();
  }

  public async addOrderedProducts(cartProducts: CartProduct[], orderCode: string) {
    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let orderedProductRef = currUserRef.collection('orders').doc(orderCode).collection('OrderedProducts');
    cartProducts.forEach(async (p) => {
      console.log('adding product :', JSON.stringify(p));

      await orderedProductRef.doc(p.ProductCode).set(JSON.parse(JSON.stringify(p)));

      this._orderedProducts.push(p);
    });

    this.emitOrderedProductsSubject();
  }

  public async getOrderedProductsByOrderCode(orderCode: string) {
    let currUserRef = this.db.collection('customers').doc(this._credentialsService.credentials.EnterpriseCode);
    let orderedProductRef = currUserRef
      .collection('orders')
      .doc(orderCode)
      .collection('OrderedProducts')
      .withConverter(CartProductConverter);

    this._orderedProducts = [];

    await orderedProductRef.get().then((snap) => {
      snap.forEach((doc) => {
        this._orderedProducts.push(doc.data());
      });
    });

    this.emitOrderedProductsSubject();
  }

  init() {
    this.getTfCommands().then();
  }
}
