export class User {
  public Id: number;
  public FirstName?: string;
  public LastName: string;
  public Token?: string;
  public Phone: string;
  public UserCode: string;
  public EnterpriseCode: string;
  public Email: string;
  public Password: string;
  public IdProfile: number;
  public SubscriptionDate: Date;
  public DiscountRate: number;

  constructor() {
    this.FirstName = '';
    this.LastName = '';
    this.Phone = '';
    this.EnterpriseCode = '';
    this.Password = '';
    this.Email = '';
    this.IdProfile = 10; // normal customer, 0=Application Owner, 1=Super Admin, 4=Manager
  }
}

export const UserConverter = {
  toFirestore(c: User): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): User {
    const data = snapshot.data(options);

    let c = new User();
    Object.assign(c, data);

    return c;
  },
};
