<div class="row mb-5">
  <div class="col-xl-8 text-center text-xl-left">
    <h1 class="bold text-dark my-0" translate>Se connecter</h1>
    <!-- <p class="my-0">
      <span translate>Don't have an account yet?</span>
      <a routerLink="/register" class="text-primary bold" translate>Create it here</a>
    </p> -->
  </div>

  <!-- BUTTON DE TRADUCTION
    <div class="col-xl-4 align-self-end text-center text-xl-right">
      <prx-button-language-selector></prx-button-language-selector>
    </div>
  !-->
</div>

<form (ngSubmit)="login()" [formGroup]="form" class="cozy" novalidate>
  <div class="alert alert-danger alert-icon" [hidden]="!error || isLoading" translate>
    Username or password incorrect.
  </div>

  <div class="form-group">
    <label class="control-label bold small text-uppercase" translate>Adresse email</label>
    <input
      type="text"
      formControlName="username"
      class="form-control bg-light form-control-rounded"
      placeholder="Entrez votre adresse e-mail"
    />
    <small [hidden]="form.controls.username.valid || form.controls.username.untouched" class="text-danger" translate>
      Le nom est requis
    </small>
  </div>

  <div class="form-group">
    <label class="control-label bold small text-uppercase" translate>Mot de Passe</label>
    <input
      type="password"
      formControlName="password"
      class="form-control bg-light form-control-rounded"
      placeholder="Entrer votre mot de passe"
    />
    <small [hidden]="form.controls.password.valid || form.controls.password.untouched" class="text-danger" translate>
      Le mot de passe est requis
    </small>
  </div>

  <div class="form-group">
    <prx-checkbox formControlName="remember" label="Se souvenir de moi" [id]="'rememberme'"></prx-checkbox>
  </div>

  <div class="form-group mb-0 d-flex align-items-center justify-content-between">
    <a routerLink="/forgot" class="text-warning small" translate>Mot de passe oublié ?</a>

    <prx-button
      type="submit"
      color="primary"
      [icon]="longArrowAltRight"
      [isLoading]="isLoading"
      [disabled]="form.invalid"
    >
      <span translate>Se connecter</span>
    </prx-button>
  </div>
</form>
