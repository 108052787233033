import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationAdminGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationAdminGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.credentialsService.credentials.admin) {
      return true;
    }

    log.debug('Not Admin, redirecting and adding redirect url...');
    this.router.navigate(['/cda'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
