import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  CustomerProject,
  KeyValuePair,
  CartProduct,
  Supplier,
  Enterprise,
  Project,
  ProjectConverter,
} from '../../models';

import * as firebase from 'firebase';
import { CredentialsService, Logger } from '@app/@core';

const logger = new Logger('RefDataService');

@Injectable({
  providedIn: 'root',
})
export class RefDataService {
  private db = firebase.default.firestore();

  private _func = firebase.default.functions();

  constructor(private _credentialsService: CredentialsService) {
    this.init();
  }

  private _suppliers: Supplier[] = [];
  private _unityList: KeyValuePair[] = [];
  private _statusList: KeyValuePair[] = [];
  //private _months: KeyValuePair[] = [];
  private _projects: Project[] = [];
  private _allProducts: CartProduct[] = [];

  SuppliersSubject = new Subject<Supplier[]>();
  UnityListSubject = new Subject<KeyValuePair[]>();
  StatusListSubject = new Subject<KeyValuePair[]>();
  MonthsListSubject = new Subject<KeyValuePair[]>();
  CustomerProjectsSubject = new Subject<Project[]>();
  AllProductsSubject = new Subject<CartProduct[]>();

  _months = [
    { Key: 1, Value: 'Janvier' },
    { Key: 2, Value: 'Fevrier' },
    { Key: 3, Value: 'Mars' },
    { Key: 4, Value: 'Avril' },
    { Key: 5, Value: 'Mai' },
    { Key: 6, Value: 'Juin' },
    { Key: 7, Value: 'Juillet' },
    { Key: 8, Value: 'Aout' },
    { Key: 9, Value: 'Septembre' },
    { Key: 10, Value: 'Octobre' },
    { Key: 11, Value: 'Novembre' },
    { Key: 12, Value: 'Decembre' },
  ];

  public emitSuppliersSubject() {
    this.SuppliersSubject.next(this._suppliers);
  }

  public emitunityListSubject() {
    this.UnityListSubject.next(this._unityList);
  }

  public emitStatusListSubject() {
    this.StatusListSubject.next(this._statusList);
  }

  public emitMonthsListSubject() {
    this.MonthsListSubject.next(this._months);
  }

  public emitCustomerProjectsSubject() {
    this.CustomerProjectsSubject.next(this._projects.slice());
  }

  public emitAllProductsSubject() {
    this.AllProductsSubject.next(this._allProducts);
  }

  public emitAllRefData() {
    this.emitSuppliersSubject();
    this.emitAllProductsSubject();
    this.emitCustomerProjectsSubject();
    this.emitStatusListSubject();
    //this.emitunityListSubject();
  }

  public async getEnterpriseByCode(enterpriseCode: string) {
    let currUserRef = this.db.collection('customers').doc(enterpriseCode);
    let e = await currUserRef.get();
    let enterprise = new Enterprise();
    if (e) {
      Object.assign(enterprise, e.data());
      this._credentialsService.setUserEnterprise(enterprise);
    }

    return new Promise((rs, rj) => rs(enterprise));
  }

  public async getProjectOfEnterprise(enterpriseCode: string) {
    this._projects = [];

    let projectRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .withConverter(ProjectConverter);

    let snap = await projectRef.get();

    snap.forEach((doc) => {
      if (!this._projects.some((x) => x.Code == doc.data().Code)) this._projects.push({ ...doc.data() });
    });

    this.emitCustomerProjectsSubject();

    return this._projects;
  }

  public sendEmail(title: string, message: string, to: string) {
    let sendEmailEndpoint = this._func.httpsCallable('sendEmail');
    let data = {
      title: title,
      message: message,
      phone: '0752439843',
      to: to,
    };

    sendEmailEndpoint(data).then((result) => {
      logger.debug('Email sent !');
    });
  }

  init() {
    this._suppliers = [
      { Id: 1, Name: 'BENS Groupe', BusinessDescription: 'Le commerce autrement' },
      { Id: 2, Name: 'Ciment Force', BusinessDescription: 'Vente de ciment de haute qualité' },
    ];

    this._unityList = [
      { Key: 'Kg', Value: 'Kilogramme', Id: 1 },
      { Key: 'L', Value: 'Litre', Id: 2 },
      { Key: 'Piece', Value: 'Piece', Id: 3 },
    ];

    this._statusList = [
      { Id: 1, Key: 1, Value: 'En création' },
      { Id: 2, Key: 2, Value: 'Commande créée' },
      { Id: 3, Key: 3, Value: 'Devis en cours' },
      { Id: 4, Key: 4, Value: 'Devis à valider' },
      { Id: 5, Key: 5, Value: 'Traitement en cours' },
      { Id: 6, Key: 6, Value: 'Livraison en cours' },
      { Id: 7, Key: 7, Value: 'Commande livrée' },
      { Id: 8, Key: 8, Value: 'Commande close' },
      { Id: 9, Key: 9, Value: 'Supprimée' },
      { Id: 10, Key: 10, Value: 'Devis refusé' },
    ];

    this._allProducts = <CartProduct[]>[
      { Id: 1, ProductCode: 'BC-OOJN', Label: 'Ciment Fort V1', BasePrice: 500 },
      { Id: 2, ProductCode: 'BC-OOJN', Label: 'Ciment Fort V2', BasePrice: 500 },
      { Id: 3, ProductCode: 'BC-OOJN', Label: 'Ciment Fort V3', BasePrice: 500 },
      { Id: 4, ProductCode: 'BC-OOJN', Label: 'Ciment Fort V4', BasePrice: 500 },
      { Id: 5, ProductCode: 'BC-OOJN', Label: 'Ciment Fort V5', BasePrice: 500 },
    ];
  }
}
