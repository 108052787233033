export class CartProduct {
  Id: number;
  ProductCode: string;
  Label: string;
  BasePrice: number;
  Discount?: number;
  DiscountPrice?: number;
  Quantity?: number;
  Comment?: string;
  MouvmentType?: string;
  MeasureUnit?: string; //Key
  OrderCode?: string;
  ProductRefCode: string;
  PriceTTC: number;

  /**
   *
   */

  constructor(orderCode?: string, id?: number, code?: string, refCode?: string, label?: string, basePrice?: number) {
    this.Id = id;
    this.ProductCode = code;
    this.Label = label;
    this.BasePrice = basePrice;
    this.Quantity = 1;
    this.OrderCode = orderCode;
    this.MouvmentType = null;
    this.MeasureUnit = null;
    this.Comment = null;
    this.DiscountPrice = null;
    this.Discount = null;
    this.ProductRefCode = refCode;
  }
}

export const CartProductConverter = {
  toFirestore(p: CartProduct): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, p);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): CartProduct {
    const data = snapshot.data(options);
    //let p=new CartProduct(data.orderCode,data.Id,data.ProductCode,data.Label,data.BasePrice);
    let p = new CartProduct();

    Object.assign(p, data);

    return p;
  },
};
