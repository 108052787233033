import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlsModule } from '../form-controls/form-controls.module';

import { VerticalTimelineBasicComponent } from './vertical-timeline-basic/vertical-timeline-basic.component';
import { VerticalTimelineAdvancedComponent } from './vertical-timeline-advanced/vertical-timeline-advanced.component';
import { TimelineStepComponent } from './vertical-timeline-advanced/timeline-step/timeline-step.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { IconsModule } from '../icons/icons.module';
import { SharedModule } from '@app/@shared';

const exports = [VerticalTimelineBasicComponent, VerticalTimelineAdvancedComponent, TimelineStepComponent];

@NgModule({
  declarations: [...exports],
  imports: [CdkStepperModule, SharedModule],
  exports,
})
export class TimelinesModule {}
