import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  faTable,
  faEdit,
  faChartLine,
  faCalendarAlt,
  faPuzzlePiece,
  faTh,
  faReceipt,
  faLayerGroup,
  faInbox,
  faCircle,
  faUserShield,
  faCog,
  faFileAlt,
  faKey,
  faUserPlus,
  faUserSecret,
  faUsers,
  faMagic,
  faHome,
  faStore,
  faSuitcase,
  faCheckSquare,
  faLifeRing,
  faCloudUploadAlt,
  faAddressCard,
  faCube,
  faShoppingBag,
  faCubes,
} from '@fortawesome/free-solid-svg-icons';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';

import { NavigationOptions } from '../models/navigation';
import { CredentialsService } from '@app/@core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private credentialsService: CredentialsService) {}

  getNavigationItems(): Observable<NavigationOptions[]> {
    return of(
      [
        {
          title: 'Admin',
          icon: { name: faLayerGroup },
          items: [
            {
              icon: { name: faShoppingBag },
              title: 'commandes',
              link: 'cda-admin/orders',
            },
            {
              icon: { name: faAddressCard },
              title: 'clients',
              link: 'cda-admin/clients',
            },
            {
              icon: { name: faStore },
              title: 'Stocks',
              link: 'cda-admin/stocks',
            },

            {
              icon: { name: faCubes },
              title: 'Products',
              link: 'cda-admin/products',
            },

            {
              icon: { name: faReceipt },
              title: 'Budget',
              link: 'cda-admin/budget',
            },

            {
              title: 'Chargements',
              icon: { name: faCloudUploadAlt },
              items: [
                { link: 'cda-admin/loaders/products', title: 'Produits', icon: { letter: 'P' } },
                { link: 'cda-admin/loaders/commands', title: 'Commandes', icon: { letter: 'C' } },
                { link: 'cda-admin/loaders/stocks', title: 'Stocks', icon: { letter: 'S' } },
                { link: 'cda-admin/loaders/budget', title: 'Budget', icon: { letter: 'B' } },
              ],
            },
          ],
        },
      ].filter(Boolean)
    );
  }
}
