import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/@core';
import { Project, ProjectConverter } from '@app/content/models';
import * as firebase from 'firebase';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class ProjectManagementService {
  private db = firebase.default.firestore();

  constructor(private _credentialsService: CredentialsService) {
    //this.init();
  }

  private _projects: Project[] = [];

  public projectsSubject = new Subject<Project[]>();

  public emitProjectsSubject() {
    this.projectsSubject.next(this._projects.slice());
  }

  public async getProjects(enterpriseCode: string) {
    this._projects = [];

    let projectsRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .withConverter(ProjectConverter);

    await projectsRef.get().then((snap) => {
      snap.forEach((doc) => {
        if (doc.data().Code) this._projects.push(doc.data());

        console.log('data got :', doc.data());
      });
    });

    this.emitProjectsSubject();

    return this._projects;
  }

  public async getProjectByCode(projectCode: string, enterpriseCode: string): Promise<Project> {
    let projectsRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .withConverter(ProjectConverter);

    const p = await projectsRef.get();

    if (p.exists) {
      return p.data();
    }
  }

  public async createProject(project: Project, enterpriseCode: string) {
    let enterpriseRef = this.db.collection('customers').doc(enterpriseCode);
    let projectsRef = enterpriseRef.collection('Projects');

    let projectsCount = 0;

    //Increment enterprise's projects counter. don't use natural increment, use fierebase.fieldValue.increment to keep data synced throught all users
    await enterpriseRef.update({ ProjectsCount: firebase.default.firestore.FieldValue.increment(1) });

    //get current enterprise from db with fresh data
    let dbProjectCounter = await enterpriseRef.get();

    projectsCount = Math.floor(dbProjectCounter.data().ProjectsCount);

    //get 6 digit from project count number
    const last4digits = projectsCount.toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false });
    const userId4Digit = this._credentialsService.credentials.Id.toLocaleString('en-US', {
      minimumIntegerDigits: 3,
      useGrouping: false,
    });
    //create project code with template PROJECTxxxxxxIDxxxx
    let projectCode = 'BE' + userId4Digit + (Math.floor(Math.random() * 10) + 1) + 'PROJ' + last4digits;

    project.Code = projectCode;
    project.Id = projectsCount;

    console.log('saving project:', Object.assign({}, project));

    await projectsRef.doc(project.Code).set(JSON.parse(JSON.stringify(project)));

    this._projects.push(project);

    this.emitProjectsSubject();

    return new Promise<Project>((rs, rj) => rs(project));
  }

  public async updateProject(project: Project, enterpriseCode: string) {
    let projectsRef = this.db.collection('customers').doc(enterpriseCode).collection('Projects');

    console.log('saving project:', Object.assign({}, project), JSON.parse(JSON.stringify(project)));

    await projectsRef.doc(project.Code).update(JSON.parse(JSON.stringify(project)));

    let any = this._projects.filter((x) => x.Code === project.Code);
    let c: Project;
    if (any) {
      c = any[0];

      Object.assign(c, project);
    } else {
      c = project;
    }

    this.emitProjectsSubject();
  }

  public async deleteProject(project: Project, enterpriseCode: string) {
    let projectsRef = this.db.collection('customers').doc(enterpriseCode).collection('Projects');

    await projectsRef.doc(project.Code).delete();

    this._projects = this._projects.filter((x) => x.Code !== project.Code);

    this.emitProjectsSubject();
  }
}
