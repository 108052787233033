import { Product } from './product';
import { Project } from './project';

export class ProductStock {
  ProductCode: string;
  Quantity: number;
  Source?: string;

  CodeClient?: string;
  Project?: Project;
  Product?: Product;
}

export const ProductStockConverter = {
  toFirestore(c: ProductStock): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): ProductStock {
    const data = snapshot.data(options);

    let c = new ProductStock();
    c.ProductCode = data.ProductCode;
    c.Quantity = data.Quantity;
    c.Source = data.Source;

    return c;
  },
};

export interface ProductInOutForm {
  project: any;
  mvtDate: Date;
  mvtType: string;
  quantity: number;
  comment: string;
}
