import { Component, OnInit } from '@angular/core';
import { AuthenticationService, BaseComponent, CredentialsService } from '@core';
import { Router } from '@angular/router';

@Component({
  selector: 'prx-header-user-account',
  templateUrl: './header-user-account.component.html',
  styleUrls: ['./header-user-account.component.scss'],
})
export class HeaderUserAccountComponent extends BaseComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private _crudentials: CredentialsService,
    private router: Router
  ) {
    super();
  }

  currentUser: any;

  ngOnInit() {
    if (this._crudentials.isAuthenticated) this.currentUser = this._crudentials.credentials;
  }

  logout() {
    if (this._crudentials.isAuthenticated()) {
      this.authenticationService.logout().subscribe(() => this.redirect());
    }
  }

  redirect() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }
}
