import { Injectable } from '@angular/core';
import { Enterprise } from '@app/content/models';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  public selectedEnterpriseSubject = new Subject<Enterprise>();

  public updateTransactionRefreshToken = new Subject<any>();

  generateFiveYears(): string[] {
    let years: string[] = [];
    const pastFiveYears = new Date().getFullYear() - 3;
    years.push(String(pastFiveYears));
    for (let index = 1; index <= 5; index++) {
      years.push(String(pastFiveYears + index));
    }
    years.unshift('ALL');

    return years;
  }
}
