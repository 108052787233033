import { Product } from './product';
import { Project } from './project';

export class Transaction {
  Type: string;
  Quantity: number;
  TransactionDate: Date;
  Comment: string;
  ProjectDestinationRefCode?: string;
  Source: string;
  Author: string;
  AuthorCode: string;

  Product?: Product;
  ProjectDes?: Project;
}

export const TransactionConverter = {
  toFirestore(c: Transaction): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): Transaction {
    const data = snapshot.data(options);

    let c = new Transaction();
    c.Type = data.Type;
    c.Quantity = data.Quantity;
    c.Source = data.Source;
    c.Author = data.Author;
    c.AuthorCode = data.AuthorCode;
    c.TransactionDate = new Date(data.TransactionDate);
    c.Comment = data.Comment;
    c.ProjectDestinationRefCode = data.ProjectDestinationRefCode;

    return c;
  },
};
