import { Injectable } from '@angular/core';
import { CartProduct, Product } from '@app/content/models';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class OrderCartService {
  private _products: CartProduct[];

  public CurrentOrderCode: string;

  CartProductsSubject = new Subject<CartProduct[]>();
  CartTotalBasePriceSubject = new Subject<number>();

  constructor() {
    this._products = [];
  }

  resetCartProducts(products: CartProduct[]) {
    this._products = products;
  }

  addOrUpdate(product: Product, quantity?: number, comment?: string) {
    if (!product) return;

    let p = new CartProduct(
      this.CurrentOrderCode,
      product.ProductId,
      product.ProductCode,
      product.ProductRefCode,
      product.Label,
      product.BasePrice
    );
    p.MeasureUnit = product.Unit;
    p.Comment = comment;
    p.Quantity = quantity;

    let finder = this._products.filter((x) => x.ProductCode === p.ProductCode);

    let found: CartProduct;

    if (finder && finder.length >= 1) {
      found = finder[0];
      found.Quantity = quantity;
      found.Comment = comment;
    } else this._products.push(p);

    const total = this._products.reduce((acc, current) => acc + current.BasePrice * current.Quantity, 0);

    this.CartProductsSubject.next(this._products);
    this.CartTotalBasePriceSubject.next(total);
  }

  removeProduct(product: CartProduct) {
    let findIndex = this._products.findIndex((x) => x.ProductCode === product.ProductCode);

    this._products.splice(findIndex, 1);
    this.emitCartPoduct();
  }

  getProducts() {
    return this._products;
  }

  emitCartPoduct() {
    this.CartProductsSubject.next(this._products.slice());
  }

  clearCart() {
    this._products = [];
    this.CartProductsSubject.next(this._products);
  }
}
