import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger, AuthenticationService, BaseFormComponent, CredentialsService } from '@core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { faCheck, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faUser, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { finalize } from 'rxjs/operators';
import { RedirectService } from '@core/services/redirect.service';
import { RefDataService } from '@app/content/cda-common/services';
import { ToastrService } from 'ngx-toastr';

const log = new Logger('Login');

@Component({
  selector: 'prx-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent extends BaseFormComponent implements OnInit {
  longArrowAltRight = faLongArrowAltRight;
  faUser = faUser;
  faArrowAltCircleRight = faArrowAltCircleRight;
  check = faCheck;

  constructor(
    //private router: Router,
    private _redirect: RedirectService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private _refDataService: RefDataService,
    private toastr: ToastrService
  ) {
    super();
    this.isLoading = false;

    if (credentialsService.isAuthenticated()) _redirect.toHome();

    this.createForm();
  }

  ngOnInit() {}

  /**
   * Logs-in the user
   * @param form The form value: user + password
   */
  login() {
    if (this.form.valid) {
      this.isLoading = true;

      let value = {
        username: this.form.get('username').value,
        password: this.form.get('password').value,
        profile: this.form.get('profile').value,
        remember: this.form.get('remember').value,
      };
      this.authenticationService
        .login(value)
        .pipe(
          finalize(() => {
            this.form.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (credentials) => {
            if (!credentials.admin) {
              this.toastr.error(
                `L'utilisateur ${credentials.username} n'a pas suffisemment de droits pour se connecter à ce site.`
              );
            } else {
              log.debug(`${credentials.username} successfully logged in`);
              this.toastr.success(`l'utilisateur ${credentials.username} est bien connecté !`);
              this.route.queryParams.subscribe((params) => this.redirect(params));
              this._refDataService.getEnterpriseByCode(credentials.EnterpriseCode).then();
            }
          },
          (error) => {
            log.debug(`Login error: ${error}`);
            this.error = error;
            this.isLoading = false;
          }
        );
    }
  }

  redirect(params: Params) {
    if (params.redirect) {
      this._redirect.to(params.redirect, { replaceUrl: true });
    } else {
      this._redirect.toHome();
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      profile: [9, Validators.required],
      remember: false,
    });
  }
}
