export class Product {
  ProductId: number;
  ProductType: string;
  ProductCode: string;
  Label: string;
  BasePrice: number;
  Discount: number;
  Unit: string;
  ProductRefCode: string;
  PriceTTC: number;
}

export const ProductConverter = {
  toFirestore(c: Product): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): Product {
    const data = snapshot.data(options);

    let c = new Product();
    c.ProductId = data.ProductId;
    c.ProductCode = data.ProductCode;
    c.Label = data.Label;
    c.BasePrice = data.BasePrice;
    c.Discount = data.Discount;
    c.Unit = data.Unit;
    c.ProductRefCode = data.ProductRefCode;
    c.ProductType = data.ProductType;

    return c;
  },
};

export enum OrderStatus {
  EnCreation = 1,
  CommandCreee = 2,
  DevisEnCours = 3,
  DevisAValider = 4,
  CommandeEnTraitement = 5,
  EnLivraison = 6,
  Receptionnee = 7,
  Cloturee = 8,
  SuppressionClient = 9,
  DevisRefuse = 10,
}
