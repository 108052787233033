import { Invoice } from './invoice';
import { CartProduct } from './cart-product';
import { Supplier } from './supplier';
import { CustomerProject } from './customer-project';
import { Project } from './project';

export class TfCommand {
  CommandId: number;
  OrderCode: string;
  CommandDate: Date;
  DeliveryPlannedDate: Date;
  DeliveryCompleteDate: Date;
  DeliveryWishDate?: Date;

  BasePrice: number;
  BensPrice: number;
  ShippingPrice: number;
  OrderTotalPriceHT: number;
  OrderTotalPrice: number;

  TvaAmount: number;

  DiscountPrice: number;
  Discount: number;
  ClientName: string;
  ClientCode: string;
  OrderedBy: string;
  NameOrderedBy: string;

  OrderStatus: number;
  IsCommandEditable: boolean;

  Comment: string;

  OrderRefCode?: string;
  Project?: Project; //CustomerProject
  ProjectRefCode?: string; //CustomerProject
  Supplier: Supplier;
  CartProducts?: CartProduct[] = [];

  // Use For Import management
  NumOrder?: number;
  Source?: string;

  /**
   *
   */
  constructor() {
    this.OrderStatus = 1;
    this.ClientName = '';
    this.ClientCode = '';
    this.OrderedBy = '';
    this.ShippingPrice = 0;
    this.TvaAmount = 0;
    this.OrderTotalPriceHT = 0;
    this.Project = new Project(); //CustomerProject()
    this.ProjectRefCode = '';
    this.Supplier = new Supplier();
    this.IsCommandEditable = false;
  }
}

export const TfCommandConverter = {
  toFirestore(c: TfCommand): firebase.default.firestore.DocumentData {
    let obj = {};
    console.log('cccccc:', c);
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): TfCommand {
    const data = snapshot.data(options);

    let c = new TfCommand();
    c.CommandDate = new Date(data.CommandDate);
    c.CommandId = data?.CommandId ?? null;
    c.ClientName = data.ClientName;
    c.Comment = data.Comment;
    c.ClientCode = data.ClientCode;
    c.OrderedBy = data.OrderedBy;
    c.NameOrderedBy = data.NameOrderedBy;
    c.ShippingPrice = data?.ShippingPrice ?? 0;
    c.TvaAmount = data.TvaAmount ?? 0;
    c.BensPrice = data.BensPrice ?? 0;
    c.Discount = data.Discount;
    c.DiscountPrice = data?.DiscountPrice ?? 0;
    c.OrderTotalPrice = data.OrderTotalPrice;
    c.OrderTotalPriceHT = data.OrderTotalPriceHT ?? 0;
    c.ProjectRefCode = data.ProjectRefCode ?? '';

    //console.log('cccccc:', c, 'dddd:', data);

    if (data.DeliveryCompleteDate) c.DeliveryCompleteDate = new Date(data.DeliveryCompleteDate);

    if (data.DeliveryPlannedDate) c.DeliveryPlannedDate = new Date(data.DeliveryPlannedDate);

    if (data.DeliveryWishDate && data.DeliveryWishDate != '') c.DeliveryWishDate = new Date(data.DeliveryWishDate);

    c.IsCommandEditable = data?.IsCommandEditable;
    c.OrderCode = data.OrderCode;
    c.OrderStatus = data.OrderStatus;
    c.BasePrice = data.BasePrice;
    if (data.Project) {
      Object.assign(c.Project, data.Project);
    }

    if (data.Supplier) Object.assign(c.Supplier, data.Supplier);

    return c;
  },
};
