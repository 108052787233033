import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CredentialsService } from './credentials.service';
import { LoginContext, RegisterContext, AuthorizationEntity } from './authentication.models';
import { ApiService } from '../api/api.service';
import { User } from '@app/content/models/user';

import * as firebase from 'firebase';

const routes = {
  auth: () => `/SSO/Authenticate`,
};
/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private loggedIn: boolean;

  get isAuthenticated() {
    return this.loggedIn;
  }

  private db = firebase.default.firestore();

  constructor(private credentialsService: CredentialsService, private _apiService: ApiService) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<AuthorizationEntity> {
    console.log('login service !!!');

    let promise = new Promise(async (resolve, reject) => {
      try {
        await this.authenticateUser(context.username, context.password, context.remember);
        resolve(this.credentialsService.credentials);
      } catch (error) {
        reject(error);
      }
    });

    return from(promise).pipe(
      map((response: any) => {
        this.loggedIn = true;

        return response;
      })
    );
  }

  private async authenticateUser(email: string, password: string, rememberMe?: boolean) {
    let credentials = await firebase.default.auth().signInWithEmailAndPassword(email, password);

    let token = await firebase.default.auth().currentUser.getIdToken();

    let users = await this.db.collectionGroup('employees').where('Email', '==', email).get();

    if (!users.empty) {
      let user = users.docs[0].data();

      let data: AuthorizationEntity = {
        Id: user.Id,
        username: user.FirstName + ' ' + user.LastName,
        accessToken: token,
        fullName: user.FirstName + ' ' + user.LastName,
        admin: user.IdProfile == 100,
        authorized: true,
        email: credentials.user.email,
        expiresIn: 50,
        newUser: false,
        uid: credentials.user.uid,
        EnterpriseCode: user.EnterpriseCode,
        UserCode: user.UserCode,
      };

      this.credentialsService.setCredentials(data, rememberMe);
      this.loggedIn = true;
    }
  }
  /**
   * Registers the user.
   * @param context The register parameters.
   * @return The user credentials.
   */
  register(context: RegisterContext): Observable<AuthorizationEntity> {
    let currUserRef = this.db.collection('customers').doc(context.EnterpriseCode);
    currUserRef.update({ OrdersCount: firebase.default.firestore.FieldValue.increment(1) });

    //TODO:
    //insert enterprise code in user
    //create user code
    //send email for password

    // Replace by proper registration call
    const data: AuthorizationEntity = {
      Id: 2,
      username: context.username,
      accessToken: '654321',
      fullName: '',
      admin: true,
      authorized: true,
      email: '',
      expiresIn: 1,
      newUser: false,
    };

    return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }
}
