import { Transaction } from '@app/content/models';
import { BudgetService } from '@app/content/cda-common/services/budget.service';
import { ProductBudget, ProductBudgetConverter } from './../../models/product-budget';
import { OrderStatus } from './../../models/product';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/@core';
import * as firebase from 'firebase';
import { Observable, of, Subject } from 'rxjs';
import {
  CartProduct,
  CartProductConverter,
  Enterprise,
  EnterpriseConverter,
  TfCommand,
  TfCommandConverter,
} from '../../models';
import { ProductsStockService } from './products-stock.service';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class CommandsAdminService {
  private db = firebase.default.firestore();

  constructor(
    private _credentialsService: CredentialsService,
    private _productsService: ProductsService,
    private _productsStockService: ProductsStockService,
    private _budgetService: BudgetService
  ) {
    //this.init();
  }

  private _tfCommandsAll: TfCommand[] = [];

  private _orderedProducts: CartProduct[] = [];

  public tfCommandsAllSubject = new Subject<TfCommand[]>();
  public orderedProductsSubject = new Subject<CartProduct[]>();
  public orderedProductsTotalBasePriceSubject = new Subject<number>();

  public emitTfCommandsAllSubject() {
    this.tfCommandsAllSubject.next(this._tfCommandsAll.slice());
  }

  public emitOrderedProductsSubject() {
    this.orderedProductsSubject.next(this._orderedProducts.slice());

    const total = this._orderedProducts.reduce((acc, current) => acc + current.BasePrice * current.Quantity, 0);
    this.orderedProductsTotalBasePriceSubject.next(total);
  }

  public async getTfCommandsByEnterprise(entrepriseCode: string): Promise<TfCommand[]> {
    this._tfCommandsAll = [];

    let currUserRef = this.db.collection('customers').doc(entrepriseCode);
    let ordersRef = currUserRef.collection('orders').withConverter(TfCommandConverter);

    await ordersRef.get().then((snap) => {
      snap.forEach((doc) => {
        console.log('this._tfCommandsAll', doc.data());
        this._tfCommandsAll.push(doc.data());
      });
    });

    this.emitTfCommandsAllSubject();

    return this._tfCommandsAll.sort((a, b) => new Date(b.CommandDate).getTime() - new Date(a.CommandDate).getTime());
  }

  public async getTfCommandById(entrepriseCode: string, id: number) {
    console.log('in commandlist :', this._tfCommandsAll);

    if (this._tfCommandsAll.length <= 0) {
      await this.getTfCommandsByEnterprise(entrepriseCode);

      console.log('loaded commands :', this._tfCommandsAll);
    }

    let p = this._tfCommandsAll.filter((x) => x.CommandId == id)[0];
    console.log(id, p);

    return new Promise((resolve, reject) => {
      resolve(p);
    });
  }

  public async getTfCommandByOrderCode(entrepriseCode: string, orderCode: string) {
    if (this._tfCommandsAll.length <= 0) {
      await this.getTfCommandsByEnterprise(entrepriseCode);
    }

    let p = this._tfCommandsAll.filter((x) => x.OrderCode == orderCode)[0];

    return new Promise((resolve, reject) => {
      resolve(p);
    });
  }

  public async updateEntrepriseTfCommand(enterpriseCode: string, tfCommand: TfCommand, cartProducts?: CartProduct[]) {
    return this.db.runTransaction(async (transaction) => {
      const year = new Date(tfCommand.CommandDate).getFullYear();
      const month = new Date(tfCommand.CommandDate).getMonth() + 1; //getmonth is a zero based counter. it return 1 for feb, 0 for jan

      let currUserRef = this.db.collection('customers').doc(enterpriseCode);
      let ordersRef = currUserRef.collection('orders');

      console.log('saving command:', Object.assign({}, tfCommand), JSON.parse(JSON.stringify(tfCommand)));

      /****************************** WE UPDATE PRODUCT BUDGET WHEN WE CLOTURE COMMAND **************/
      if (tfCommand.OrderStatus === OrderStatus.Cloturee && cartProducts?.length > 0) {
        await this._budgetService.updateBudgetProductFromOrder(enterpriseCode, tfCommand, cartProducts, year, month);
      }
      /****************************** ************************************************* ************/

      await transaction.update(ordersRef.doc(tfCommand.OrderCode), JSON.parse(JSON.stringify(tfCommand)));
      //ordersRef.doc(tfCommand.OrderCode).update(JSON.parse(JSON.stringify(tfCommand)));

      let exist = this._tfCommandsAll.filter((x) => x.CommandId == tfCommand.CommandId)[0];

      if (exist) {
        Object.assign(exist, tfCommand);
      } else {
        this._tfCommandsAll.push(tfCommand);
      }

      this.emitTfCommandsAllSubject();
    });
    //sessionStorage.setItem('tFCommandSelected', JSON.stringify(tfCommand));
  }

  public async addOrderedProductsEntrepriseTfCommand(
    enterpriseCode: string,
    cartProducts: CartProduct[],
    orderCode: string
  ) {
    let currUserRef = this.db.collection('customers').doc(enterpriseCode);
    let orderedProductRef = currUserRef.collection('orders').doc(orderCode).collection('OrderedProducts');
    cartProducts.forEach(async (p) => {
      console.log('adding product :', JSON.stringify(p));

      await orderedProductRef.doc(p.ProductCode).set(JSON.parse(JSON.stringify(p)));

      /* this._orderedProducts.push(p);

      this.emitOrderedProductsSubject(); */
    });
  }

  public async getOrderedProductsByOrderCodeForEntreprise(enterpriseCode: string, orderCode: string) {
    let currUserRef = this.db.collection('customers').doc(enterpriseCode);
    let orderedProductRef = currUserRef
      .collection('orders')
      .doc(orderCode)
      .collection('OrderedProducts')
      .withConverter(CartProductConverter);

    this._orderedProducts = [];

    await orderedProductRef.get().then((snap) => {
      snap.forEach((doc) => {
        this._orderedProducts.push(doc.data());
      });
    });

    this.emitOrderedProductsSubject();
  }

  public async DeleteOrderedProduct(enterpriseCode: string, orderCode: string, productCode: string) {
    let currUserRef = this.db.collection('customers').doc(enterpriseCode);
    let orderedProductRef = currUserRef
      .collection('orders')
      .doc(orderCode)
      .collection('OrderedProducts')
      .doc(productCode);

    await orderedProductRef.delete();
  }

  public async createCommandFromImportedData(data: TfCommand[]): Promise<any> {
    return await this.db.runTransaction(async (transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      for (const elt of data) {
        const source = elt.Source;

        const year = elt.CommandDate.getFullYear();
        const month = elt.CommandDate.getMonth() + 1; //getmonth is a zero based counter. it return 1 for feb, 0 for jan

        /***************************************************************** GENERATE ORDER CODE ***********************************************************/
        /************************************************************************************************************************************************/
        let currUserRef = this.db.collection('customers').doc(elt.ClientCode);
        let ordersCount = 0;

        //Increment user's orders counter. don't use natural increment, use fierebase.fieldValue.increment to keep data synced throught all users
        await currUserRef.update({ OrdersCount: firebase.default.firestore.FieldValue.increment(1) });

        //get current user from db with fresh data
        let dbUser = await currUserRef.get();
        ordersCount = Math.floor(dbUser.data().OrdersCount);

        //get 6 digit from orderscount number
        const last4digits = ordersCount.toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false });
        const userId4Digit = this._credentialsService.credentials.Id.toLocaleString('en-US', {
          minimumIntegerDigits: 4,
          useGrouping: false,
        });
        //create order code with template BExx...xODRxxxxxx
        let orderCode = 'BE' + userId4Digit + (Math.floor(Math.random() * 10) + 1) + 'ODR' + last4digits;
        /***************************************************************** GENERATE ORDER CODE ***********************************************************/
        /************************************************************************************************************************************************/

        console.log('ordersCount===>', ordersCount);
        let destinationOrderRef = this.db
          .collection('customers')
          .doc(elt.ClientCode)
          .collection('orders')
          .doc(orderCode);

        // DELETE UNSEFUL FIELDS
        delete elt.IsCommandEditable;
        delete elt.NumOrder;
        delete elt.Source;
        // DELETE UNSEFUL FIELDS - END

        elt.OrderCode = orderCode;
        elt.IsCommandEditable = true;

        elt.CommandId = ordersCount;

        elt.OrderTotalPriceHT =
          Number(elt.BensPrice ?? 0) - Number(elt.DiscountPrice ?? 0) + Number(elt.ShippingPrice ?? 0);

        elt.TvaAmount = (elt.OrderTotalPriceHT * 18) / 100;

        elt.OrderTotalPrice = elt.OrderTotalPriceHT + elt.TvaAmount;

        const getCommand = await destinationOrderRef.get();
        if (getCommand.exists) {
          throw new Error('Cette commande existe dejà');
        }

        const proj = await this._productsStockService.getProjectOfEnterpriseByCode(elt.ClientCode, elt.ProjectRefCode);
        if (!proj) {
          //await transaction.set(destinationProjectRef, JSON.parse(JSON.stringify(elt.Project)));
          throw new Error(`Le projet(entrepot) avec pour code ${elt.ProjectRefCode} n'existe pas`);
        }

        for (const product of elt.CartProducts) {
          const getProduct = this._productsService.getProductByCode(product.ProductCode);

          if (!getProduct) {
            throw new Error(`Le produit avec pour code ${product.ProductCode} n'existe pas`);
          }
        }

        elt.Project = proj;

        const commandToSave: TfCommand = JSON.parse(JSON.stringify(elt));
        delete commandToSave.CartProducts;

        //console.log("commande to save :",commandToSave);

        await transaction.set(destinationOrderRef, { ...commandToSave });

        for (const product of elt.CartProducts) {
          await transaction.set(
            destinationOrderRef.collection('OrderedProducts').doc(product.ProductCode),
            JSON.parse(JSON.stringify({ ...product, OrderCode: elt.OrderCode }))
          );
        }

        /****************************** COMMANDE CLOTUREE ************************************************* *********************/
        if (elt.OrderStatus === OrderStatus.Cloturee && elt.CartProducts?.length > 0) {
          /****************************** WE UPDATE PRODUCT BUDGET WHEN COMMAND IS IN CLOTURE STATUS**************/
          this._budgetService.updateBudgetProductFromOrder(elt.ClientCode, elt, elt.CartProducts, year, month);

          /****************************** WE CREATE TRANSACTION FOR SAVING STOCK**************/
          const transaction = new Transaction();
          transaction.Type = 'IN';
          transaction.ProjectDestinationRefCode = elt.ProjectRefCode;
          transaction.TransactionDate = elt.DeliveryCompleteDate;
          transaction.Comment = 'Import - Reprise des commandes';
          transaction.Source = source;

          this._productsStockService.createIn(elt.ClientCode, elt.ProjectRefCode, elt.CartProducts, transaction);
        }
        /****************************** ************************************************* *********************/
      }
    });
  }
}
