import { Injectable } from '@angular/core';
import {
  CartProduct,
  ProductInOutForm,
  ProductStock,
  ProductStockConverter,
  Transaction,
  TransactionConverter,
} from '@app/content/models';
import { Project, ProjectConverter } from '@app/content/models/project';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsStockService {
  private db = firebase.default.firestore();

  productStockSubject = new Subject<ProductStock[]>();

  constructor(private productService: ProductsService) {}

  public getProductsStockBelongsToEnterprises(enterpriseCode: string): Promise<any> {
    const productsStockList: ProductStock[] = [];

    let projectRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .withConverter(ProjectConverter);

    return new Promise<any>(async (resolve, reject) => {
      projectRef.get().then(async (snap) => {
        for (const doc of snap.docs) {
          const project = doc.data();

          let productStockRef = projectRef.doc(project.Code).collection('Stock').withConverter(ProductStockConverter);

          await productStockRef.get().then(async (snap) => {
            /* snap.forEach((doc) => {
              productsStockList.push({ ...doc.data(), Project: project });
            }); */
            for (const doc of snap.docs) {
              const productStock = doc.data();

              let product = await this.productService.getProductByCode(productStock.ProductCode);
              productStock.Product = product;

              productsStockList.push({ ...productStock, Project: project });
            }
          });
        }

        console.log('productsStockList =====++>', productsStockList);

        resolve(productsStockList);
      });
    });
  }

  public async getProductsStockBelongsToProjectOfEnterprise(enterpriseCode: string, projectCode: string): Promise<any> {
    const productsStockList: ProductStock[] = [];

    let projectRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .collection('Stock')
      .withConverter(ProductStockConverter);

    await projectRef.get().then(async (snap) => {
      /* snap.forEach((doc) => {
        productsStockList.push({ ...doc.data() });
      }); */
      for (const doc of snap.docs) {
        const productStock = doc.data();

        let product = await this.productService.getProductByCode(productStock.ProductCode);
        productStock.Product = product;

        productsStockList.push({ ...productStock });
      }
    });

    return productsStockList;
  }

  public async getSingleProductStockBelongsToProjectOfEnterprise(
    enterpriseCode: string,
    projectCode: string,
    productStockCode: string
  ): Promise<ProductStock> {
    let projectRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .collection('Stock')
      .doc(productStockCode)
      .withConverter(ProductStockConverter);

    return await (await projectRef.get()).data();
  }

  public async getProjectOfEnterprise(enterpriseCode: string) {
    const projectList: Project[] = [];

    let projectRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .withConverter(ProjectConverter);

    await projectRef.get().then((snap) => {
      snap.forEach((doc) => {
        projectList.push({ ...doc.data() });
      });
    });

    return projectList;
  }

  public async getProjectOfEnterpriseByCode(enterpriseCode: string, projectCode: string) {
    const projectList: Project[] = [];

    let projectRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .withConverter(ProjectConverter);

    const p = await projectRef.get();

    if (p.exists) {
      return p.data();
    }
  }

  public async getTransactionsOfProductStockInProjectOfEnterprise(
    enterpriseCode: string,
    projectCode: string,
    productStockCode: string
  ): Promise<Transaction[]> {
    const transactionsList: Transaction[] = [];

    let transactionsRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .collection('Stock')
      .doc(productStockCode)
      .collection('Transactions')
      .orderBy('TransactionDate', 'desc')
      .withConverter(TransactionConverter);

    let product = await this.productService.getProductByCode(productStockCode);

    await transactionsRef.get().then(async (snap) => {
      for (const doc of snap.docs) {
        const transaction = doc.data();

        let project = null;
        if (transaction.ProjectDestinationRefCode) {
          project = await this.getProjectOfEnterpriseByCode(enterpriseCode, transaction.ProjectDestinationRefCode);
        }

        transactionsList.push({ ...doc.data(), Product: product, ProjectDes: project });
      }
    });

    return transactionsList;
  }

  public async createOutOrTransfert(
    enterpriseCode: string,
    projectCode: string,
    productStockCode: string,
    productStock: ProductStock,
    transaction: Transaction
  ): Promise<Transaction> {
    let emitterProductStockRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .collection('Stock');

    let destinationProductStockRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(transaction.ProjectDestinationRefCode)
      .collection('Stock');

    //UPDATE productStock
    const productStockToSave = { ...productStock };
    delete productStockToSave.Product;
    delete productStockToSave.Project;

    await emitterProductStockRef.doc(productStockCode).update(JSON.parse(JSON.stringify(productStockToSave)));

    if (transaction.Type === 'TRANSFERT') {
      // CHECK IF PRODUCT STOCK EXIST IN PROJECT DESTINATION
      const p = await this.getSingleProductStockBelongsToProjectOfEnterprise(
        enterpriseCode,
        transaction.ProjectDestinationRefCode,
        productStockCode
      );
      if (p) {
        await destinationProductStockRef.doc(productStockCode).update({ Quantity: transaction.Quantity + p.Quantity });
      } else {
        await destinationProductStockRef.doc(productStockCode).set({ ...productStock, Quantity: transaction.Quantity });
      }
      await destinationProductStockRef
        .doc(productStockCode)
        .collection('Transactions')
        .add(JSON.parse(JSON.stringify(transaction)));
    }

    //Set transfert sender transaction
    let senderTransaction: Transaction = transaction;
    senderTransaction.Quantity = -senderTransaction.Quantity;
    await emitterProductStockRef
      .doc(productStockCode)
      .collection('Transactions')
      .add(JSON.parse(JSON.stringify(senderTransaction)));

    return new Promise<Transaction>((rs, rj) => rs(transaction));
  }

  public async createIn(
    enterpriseCode: string,
    projectCode: string,
    cartProducts: CartProduct[],
    transaction: Transaction
  ): Promise<Transaction> {
    let productStockRef = this.db
      .collection('customers')
      .doc(enterpriseCode)
      .collection('Projects')
      .doc(projectCode)
      .collection('Stock');

    console.log('inserting in stock');

    for (const cartProduct of cartProducts) {
      //CREATE productStock FOR EACH CartProduct
      const productStock: ProductStock = { ProductCode: cartProduct.ProductCode, Quantity: cartProduct.Quantity };

      // CHECK IF PRODUCT STOCK EXIST IN PROJECT DESTINATION
      const p = await this.getSingleProductStockBelongsToProjectOfEnterprise(
        enterpriseCode,
        projectCode,
        productStock.ProductCode
      );
      if (p) {
        console.log('in updateing stock:', { Quantity: productStock.Quantity + p.Quantity });

        await productStockRef.doc(productStock.ProductCode).update({ Quantity: productStock.Quantity + p.Quantity });
      } else {
        console.log('in adding stock:', { ...productStock, Quantity: productStock.Quantity });

        await productStockRef.doc(productStock.ProductCode).set({ ...productStock, Quantity: productStock.Quantity });
      }

      if (!transaction.TransactionDate) transaction.TransactionDate = new Date();

      let t = { ...transaction, Quantity: productStock.Quantity };
      console.log('in transaction :', t);

      await productStockRef
        .doc(productStock.ProductCode)
        .collection('Transactions')
        .add(JSON.parse(JSON.stringify(t)));
    }

    return new Promise<Transaction>((rs, rj) => rs(transaction));
  }

  public async createStockFromImportedData(data: ProductStock[]): Promise<any> {
    return await this.db.runTransaction(async (transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      for (const elt of data) {
        let destinationProjectRef = this.db
          .collection('customers')
          .doc(elt.CodeClient)
          .collection('Projects')
          .doc(elt.Project.Code);

        let destinationProductStockRef = this.db
          .collection('customers')
          .doc(elt.CodeClient)
          .collection('Projects')
          .doc(elt.Project.Code)
          .collection('Stock');

        const proj = await this.getProjectOfEnterpriseByCode(elt.CodeClient, elt.Project.Code);
        if (!proj) {
          await transaction.set(destinationProjectRef, JSON.parse(JSON.stringify(elt.Project)));
        }

        const p = await this.getSingleProductStockBelongsToProjectOfEnterprise(
          elt.CodeClient,
          elt.Project.Code,
          elt.ProductCode
        );
        if (p) {
          await transaction.update(destinationProductStockRef.doc(elt.ProductCode), {
            Quantity: elt.Quantity + p.Quantity,
          });
        } else {
          await transaction.set(destinationProductStockRef.doc(elt.ProductCode), {
            ProductCode: elt.ProductCode,
            Quantity: elt.Quantity,
          });
        }

        const _transaction = new Transaction();
        _transaction.Type = 'IN';
        _transaction.ProjectDestinationRefCode = proj.Code;
        _transaction.TransactionDate = new Date();
        _transaction.Comment = 'Import - Reprise des stocks';
        _transaction.Source = elt.Source;
        _transaction.Quantity = elt.Quantity;

        await destinationProductStockRef
          .doc(elt.ProductCode)
          .collection('Transactions')
          .add(JSON.parse(JSON.stringify(_transaction)));
      }
    }); /* .then(() => {
      console.log("Transaction successfully committed!");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
    }); */
  }
}
