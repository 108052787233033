export class Project {
  Id: number;
  Name: string;
  Code: string;
  Description: string;
}

export const ProjectConverter = {
  toFirestore(c: Project): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): Project {
    const data = snapshot.data(options);

    let c = new Project();
    c.Id = data.Id;
    c.Name = data.Name;
    c.Code = data.Code;
    c.Description = data.Description;

    return c;
  },
};
