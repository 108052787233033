// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  hmr: false,
  version: env.npm_package_version,
  serverUrl: 'https://5studios.net/themes/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'es-ES'],
  firebaseConfig: {
    apiKey: 'AIzaSyA2B5R6gS4ed0Son0VAAH89n4jqu1lfKoQ',
    authDomain: 'dev-bens-cda.firebaseapp.com',
    projectId: 'dev-bens-cda',
    storageBucket: 'dev-bens-cda.appspot.com',
    messagingSenderId: '788465485014',
    appId: '1:788465485014:web:39fea2a71d8b82fbbcf577',
    measurementId: 'G-QWTVT7Y5XZ',
  },
};
