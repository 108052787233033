import { Injectable } from '@angular/core';
import { Enterprise, EnterpriseConverter } from '@app/content/models';
import { Subject } from 'rxjs';

import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseMgtService {
  private db = firebase.default.firestore();
  private _enterprisesList: Enterprise[] = [];

  public enterprisesListSubject = new Subject<Enterprise[]>();

  constructor() {}

  public emitEnterprisesSubject() {
    this.enterprisesListSubject.next(this._enterprisesList.slice());
  }

  public async createEnterprise(enterprise: Enterprise) {
    let enterpriseRef = this.db.collection('customers');

    let customersCount = 0;

    //Increment user's orders counter. don't use natural increment, use fierebase.fieldValue.increment to keep data synced throught all users
    await enterpriseRef
      .doc('countersRef')
      .update({ CustomersCount: firebase.default.firestore.FieldValue.increment(1) });

    //get current count ref from db with fresh data
    let dbCustomerCounter = await enterpriseRef.doc('countersRef').get();

    customersCount = Math.floor(dbCustomerCounter.data().CustomersCount);

    //get 6 digit from orderscount number
    const last4digits = customersCount.toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false });
    //create order code with template BENSxxxxxx
    let enterpriseCode = 'BENS' + last4digits;

    enterprise.EnterpriseCode = enterpriseCode;

    console.log('saving enterprise:', Object.assign({}, enterpriseCode));

    const getEnterprise = await (
      await enterpriseRef.doc(enterprise.EnterpriseCode).withConverter(EnterpriseConverter).get()
    ).data();

    if (getEnterprise && getEnterprise.Email.toLocaleLowerCase() === enterprise.EnterpriseCode.toLocaleLowerCase()) {
      throw new Error('Email already exit');
    }

    await enterpriseRef.doc(enterprise.EnterpriseCode).set(JSON.parse(JSON.stringify(enterprise)));

    this._enterprisesList.push(enterprise);

    this.emitEnterprisesSubject();
  }

  public async getAllEnterprises() {
    this._enterprisesList = [];
    let usersRef = this.db.collection('customers').withConverter(EnterpriseConverter);

    await usersRef.get().then((snap) => {
      snap.forEach(async (doc) => {
        this._enterprisesList.push(doc.data());
      });
    });

    this.emitEnterprisesSubject();
  }

  public async getEnterpriseByCode(code: string) {
    if (this._enterprisesList.length <= 0) {
      await this.getAllEnterprises();
    }

    let p = this._enterprisesList.filter((x) => x.EnterpriseCode == code)[0];

    return new Promise((resolve, reject) => {
      resolve(p);
    });
  }

  public async getEnterpriseByCodeFromFireBase(enterpriseCode: string): Promise<Enterprise> {
    let enterpriseRef = this.db.collection('customers').doc(enterpriseCode).withConverter(EnterpriseConverter);

    const p = await enterpriseRef.get();

    if (p.exists) {
      return p.data();
    }
  }

  public async updateEnterprise(enterprise: Enterprise) {
    let enterpriseRef = this.db.collection('customers');

    await enterpriseRef.doc(enterprise.EnterpriseCode).update(JSON.parse(JSON.stringify(enterprise)));

    let any = this._enterprisesList.filter((x) => x.EnterpriseCode === enterprise.EnterpriseCode);
    let c: Enterprise;
    if (any) {
      c = any[0];

      Object.assign(c, enterprise);
    } else {
      c = enterprise;
    }

    this.emitEnterprisesSubject();
  }
}
