<div class="container-fluid">
  <div class="row align-items-center">
    <div
      class="col-md-6 col-lg-7 fullscreen-md d-flex justify-content-center align-items-center blanket blanket-primary alpha-8 image-background cover"
      style="background-image: url(assets/img/background_1600x700.v1.png)"
    >
      <div class="content">
        <h2 class="display-4 display-lg-3 text-contrast mt-4 mt-md-0">
          <span class="bold d-block" translate>APP_NAME</span><span class="font-italic">Administration Board</span>
        </h2>
        <p class="lead text-contrast">The only tool you'll ever need</p>

        <hr class="mt-md-6 w-25" />
        <div class="d-flex flex-column">
          <p class="small bold text-contrast">keep in touch with the lastest new on our social media</p>
          <nav class="nav mb-4">
            <a class="btn btn-rounded btn-outline-secondary brand-facebook mr-2" href="javascript:;"
              ><fa-icon [icon]="fa.facebook"></fa-icon
            ></a>
            <a class="btn btn-rounded btn-outline-secondary brand-google mr-2" href="javascript:;"
              ><fa-icon [icon]="fa.google"></fa-icon
            ></a>
            <a class="btn btn-rounded btn-outline-secondary brand-linkedin" href="javascript:;"
              ><fa-icon [icon]="fa.linkedin"></fa-icon
            ></a>
          </nav>
        </div>
      </div>
    </div>

    <div class="col-md-5 col-lg-4 mx-auto">
      <div class="login-form mt-5 mt-md-0">
        <!-- <p class="small alert alert-warning text-center rounded-pill mb-5" translate></p> -->

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
