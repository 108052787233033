import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumberPipe',
})
export class CustomNumberPipe extends DecimalPipe {
  transform(value: any, ...args: any[]): any {
    let val = Math.ceil(Number(value ?? 0));

    return super.transform(val, '1.0');
  }
}
