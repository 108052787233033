import { Component, OnInit, HostBinding, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AuthenticationService, CredentialsService, Logger } from '@core';
import { Router } from '@angular/router';
import { faInbox, faLock, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NavigationService } from '@app/layout/services/navigation.service';
import { NavigationOptions } from '@app/layout/models/navigation';

const logger = new Logger('SidenavComponent');

@Component({
  selector: 'prx-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  host: { class: 'sidenav' },
})
export class SidenavComponent implements OnInit {
  @HostBinding('class.fixed')
  @Input()
  fixed: boolean;

  @HostBinding('class.hover')
  hover: boolean;

  @HostListener('mouseenter')
  onMouseOver() {
    this.hover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hover = false;
  }

  user: any = {
    avatar: {
      picture: 'assets/img/icons/avatar-gratuit.png',
      name: 'pauline myers',
      status: 'online',
    },
    info: 'paulinemyers@example.com',
    enterpriseCode: 'BENS0000',
    userCode: 'BENS0000E000',
  };

  icons = {
    faInbox,
    faLock,
    faSignOutAlt,
  };

  navGroups: NavigationOptions[];

  @Output()
  sideNavToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.collapsed')
  @Input()
  collapsed: boolean;

  currentUser: any;

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private authenticationService: AuthenticationService,
    private _credentials: CredentialsService
  ) {}

  ngOnInit() {
    if (this._credentials.isAuthenticated) {
      this.user.info = this._credentials.credentials.email;
      this.user.avatar.name = this._credentials.credentials.username;
      this.user.enterpriseCode = this._credentials.credentials.EnterpriseCode;
      this.user.userCode = this._credentials.credentials.UserCode;
    }

    this.navigation.getNavigationItems().subscribe((groups) => (this.navGroups = groups));
  }

  toggleSidenav() {
    this.collapsed = !this.collapsed;
    this.sideNavToggled.emit(this.collapsed);
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  onNavLinkToggle(isOpen: boolean) {
    logger.debug(`Nav link toggled ${isOpen}`);
  }
}
