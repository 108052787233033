<prx-main-body
  [ngClass]="{
    'quick-sidenav-expanded': !isQuickSidenavCollapsed
  }"
>
  <prx-header [fixed]="fixedHeader">
    <prx-header-toolbar
      branded="true"
      dark="true"
      [quickSidenavCollapsed]="isQuickSidenavCollapsed"
      (quickSidenavToggled)="onQuickSidenavToggled($event)"
      [sidenavCollapsed]="isSidenavCollapsed"
      (sidenavToggled)="onSidenavToggled($event)"
    ></prx-header-toolbar>

    <prx-header-navbar
      [sidenavCollapsed]="isSidenavCollapsed"
      (sideNavToggled)="onSidenavToggled($event)"
    ></prx-header-navbar>
  </prx-header>

  <main class="body-wrapper overflow-hidden">
    <router-outlet></router-outlet>
  </main>

  <prx-footer></prx-footer>
</prx-main-body>

<prx-quick-sidenav [collapsed]="isQuickSidenavCollapsed"></prx-quick-sidenav>

<prx-page-overlay *ngIf="!isQuickSidenavCollapsed" class="quick-sidenav-overlay">
  <fa-icon [icon]="closeIcon" class="close" (click)="onQuickSidenavToggled(true)"></fa-icon>
</prx-page-overlay>
