import { Product } from './product';

export class ProductBudget {
  BudgetAmount: number;
  BudgetUsed: number;
  LastRevision?: Date;
  Quantity: number;
  QuantityUsed: number;

  RefProduct: string;
  ProjectCode: string;

  YearCode: number;
  MonthCode: number;

  Product?: Product;
  EnterpriseCode?: string;
}

export const ProductBudgetConverter = {
  toFirestore(c: ProductBudget): firebase.default.firestore.DocumentData {
    let obj = {};
    Object.assign(obj, c);
    return obj;
  },
  fromFirestore(
    snapshot: firebase.default.firestore.QueryDocumentSnapshot,
    options: firebase.default.firestore.SnapshotOptions
  ): ProductBudget {
    const data = snapshot.data(options);

    let c = new ProductBudget();
    c.BudgetAmount = data.BudgetAmount;
    c.BudgetUsed = Number(data.BudgetUsed) ?? 0;
    c.Quantity = data.Quantity;
    c.QuantityUsed = Number(data.QuantityUsed) ?? 0;
    c.RefProduct = data.RefProduct;
    if (!c.LastRevision) c.LastRevision = new Date(data.LastRevision);
    c.YearCode = data.YearCode;
    c.MonthCode = data.MonthCode;
    c.ProjectCode = data.ProjectCode;
    c.EnterpriseCode = data.EnterpriseCode;

    return c;
  },
};
